
import { classnames } from 'tailwindcss-classnames';
import { BadgeCheckIcon, ExclamationCircleIcon, ExclamationIcon, InformationCircleIcon } from '@heroicons/react/solid';

export default function Alert(props) {
  const {
    className,
    variant = 'error',
  } = props;

  return (
    <div className={classnames(
      'p-3 rounded-3xl font-medium flex flex-row items-center',
      {
        'text-red-800 bg-red-200': variant === 'error',
      },
      {
        'text-yellow-800 bg-yellow-200': variant === 'warning',
      },
      {
        'text-slate-800 bg-slate-200': variant === 'info',
      },
      {
        'text-green-800 bg-green-200': variant === 'success',
      },
      className,
    )}>
      <div className='pr-1'>
        {
          variant === 'success' && <BadgeCheckIcon width={22} height={22} className="inline -mt-1 mr-2" />
        }
        {
          variant === 'error' && <ExclamationCircleIcon width={22} height={22} className="inline -mt-1 mr-2" />
        }
        {
          variant === 'warning' && <ExclamationIcon width={22} height={22} className="inline -mt-1 mr-2" />
        }
        {
          variant === 'info' && <InformationCircleIcon width={22} height={22} className="inline -mt-1 mr-2" />
        }
      </div>
      <div className='flex grow'>
        {props.children}
      </div>
    </div>
  );
}
