
import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import qs from 'query-string';

export const onEnter = handler => e => {
  if (e.key === 'Enter') {
    return handler(e);
  }
}

export const truncAddress = (address, start = 7, end = 5) => {
  return address.substring(0, start) + '...' + address.substring(address.length - end);
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const useStateWithPromise = (initialState) => {
  const [state, setState] = useState(initialState);
  const resolverRef = useRef(null);

  useEffect(() => {
    if (resolverRef.current) {
      resolverRef.current(state);
      resolverRef.current = null;
    }
    /**
     * Since a state update could be triggered with the exact same state again,
     * it's not enough to specify state as the only dependency of this useEffect.
     * That's why resolverRef.current is also a dependency, because it will guarantee,
     * that handleSetState was called in previous render
     */
  }, [resolverRef.current, state]);

  const handleSetState = useCallback((stateAction) => {
    setState(stateAction);
    return new Promise(resolve => {
      resolverRef.current = resolve;
    });
  }, [setState])

  return [state, handleSetState];
};

export const getTimeAgo = (timestamp) => {
  const now = moment();
  const then = moment(timestamp);

  const gap = Math.floor((now - then) / 1000); // in seconds

  if (gap < 60 && gap > 0) {
    return 'just now';
  } else if (gap <= 0 && gap >= -60) {
    return 'just now'; // in the future
  } else if (Math.abs(gap) < 60 * 60) {
    // in the last hour
    return `${Math.floor(Math.abs(gap) / 60)}min ago`;
  } else if (Math.abs(gap) < 60 * 60 * 24) {
    // in the last day
    return `${Math.floor(Math.abs(gap) / (60 * 60))}hr ago`;
  } else /*if (gap < 60 * 60 * 24 * 7)*/ {
    // in the last week
    return `${Math.floor(Math.abs(gap) / (60 * 60 * 24))}d ago`;
  }
}

export const canMintToken = (roles, tokenDef, mcode) => {
  if (!roles || !tokenDef) {
    return false;
  }

  if (roles && roles.includes('admin')) {
    return true;
  }

  if (!tokenDef?.metadata?.config?.mintPrivate) {
    return true;
  }

  if (mcode) {
    return true;
  }

  return false;
}
