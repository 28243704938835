
import React from 'react';
import { classnames } from 'tailwindcss-classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

export default function Example(props) {
  const {
    variant = 'filled',
    className,
    size = 'base',
    loading = false,
    style,
    label = null,
    labelClassName = '',
    multiline = false,
    description = null,
    leftIcon = null,
    passRef = null,
    ...rest
  } = props;

  const field = React.createElement(
    multiline ? TextareaAutosize : 'input',
    {
      className: classnames(
        'disabled:opacity-60 block w-full rounded-lg',
        {
          'focus:ring-slate-500 focus:outline-none focus:border-slate-500': variant === 'outlined',
          'focus:ring-slate-500 focus:outline-none focus:border-slate-500 focus:ring-2 bg-slate-200 border-none placeholder-slate-400': variant === 'filled',
        },
        leftIcon === null && {
          'text-base px-2 py-1.5': size === 'base',
          'text-xl px-2 py-2': size === 'lg',
          'text-2xl px-3 py-3': size === 'xl',
        },
        leftIcon !== null && {
          'text-base px-2 py-1.5': size === 'base',
          'text-xl px-2 py-2 pl-8': size === 'lg',
          'text-2xl px-3 py-3': size === 'xl',
        },
        className,
      ),
      disabled: loading || rest.disabled || false,
      type: rest.type || (multiline ? undefined : 'text'),
      ref: passRef,
      ...rest,
    },
  );

  return (
    label
      ? (
        <>
          <label
            className={classnames(
              `block text-sm font-medium text-slate-700`,
              {
                'opacity-50': rest.disabled,
              },
              labelClassName,
            )}
          >
            <div className='w-full flex flex-col items-start'>
              {label}
              <div className='w-full flex flex-row items-center'>
                { leftIcon && <FontAwesomeIcon icon={leftIcon} className="absolute ml-2 w-5 h-5" /> }
                {field}
              </div>
              {description && <span className='text-slate-500'>{description}</span>}
            </div>
          </label>
        </>
      )
      : field
  );
}