
// ishika
// krish patel

import { useState, Fragment } from "react";
import { Transition } from '@headlessui/react';
import axios from 'axios';
import { onEnter } from '../lib/utils/misc';
import API from '../lib/client';
import React from "react";
import { classNames } from '../lib/utils/misc';
import { setUserId } from "../lib/userId";
import Button from './ui/button';
import TextField from './ui/textfield';
import Alert from './ui/alert';
import { DeviceMobileIcon, MailIcon, ArrowSmLeftIcon, ArrowSmRightIcon } from '@heroicons/react/solid';

export default function Login(props) {
  const [authState, setAuthState] = useState('start');
  const [number, setNumber] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [email, setEmail] = useState('');
  const [emailCode, setEmailCode] = useState('');

  const [smsRequestOut, setSmsRequestOut] = useState(false);
  const [smsRequestError, setSmsRequestError] = useState(null);
  const [smsVerifyRequestOut, setSmsVerifyRequestOut] = useState(false);
  const [smsVerifyRequestError, setSmsVerifyRequestError] = useState(null);
  const [emailRequestOut, setEmailRequestOut] = useState(false);
  const [emailRequestError, setEmailRequestError] = useState(null);
  const [emailVerifyRequestOut, setEmailVerifyRequestOut] = useState(false);
  const [emailVerifyRequestError, setEmailVerifyRequestError] = useState(null);

  const api = new API();

  const { onLogin = (() => {}) } = props;

  const onPhoneEntered = async () => {
    setSmsRequestOut(true);

    try {
      await api.post(
        '/auth/phone/send',
        { number },
        false,
      );

      setSmsRequestOut(false);
      setSmsCode('');
      setSmsRequestError(null);
      setSmsVerifyRequestError(null);
      setAuthState('sms_verify');
    } catch (e) {
      console.log(e)
      setSmsRequestError(e);
      setSmsRequestOut(false);
    }
  }

  const onEmailEntered = async () => {
    setEmailRequestOut(true);

    try {
      await api.post(
        '/auth/email/send',
        { email },
        false,
      );

      setEmailRequestOut(false);
      setEmailCode('');
      setAuthState('email_verify');
    } catch (e) {
      console.log(e);
      setEmailRequestError(e);
      setEmailRequestOut(false);
    }
  }

  const verifyPhone = async () => {
    setSmsVerifyRequestOut(true);

    try {
      await api.post(
        '/auth/phone/verify',
        { number, code: smsCode },
        false,
      );

      // setSmsVerifyRequestOut(false);
      // setAuthState('sms_verify');
      setSmsRequestError(false);
      setSmsVerifyRequestError(null);
      onLogin();
    } catch (e) {
      console.log(e)
      setSmsVerifyRequestError(e);
      setSmsVerifyRequestOut(false);
    }
  }

  const verifyEmail = async () => {
    setEmailVerifyRequestOut(true);

    try {
      await api.post(
        '/auth/email/verify',
        { email, code: emailCode },
        false,
      );

      setEmailRequestError(null);
      setEmailVerifyRequestError(null);
      onLogin();
    } catch (e) {
      console.log(e);
      setEmailVerifyRequestError(e);
      setEmailVerifyRequestOut(false);
    }
  }

  const renderStart = () => {
    return (
      <div className="text-center">
        <Button size="lg" className="w-full mb-2" onClick={() => { setAuthState('sms_start') }}>
          <DeviceMobileIcon className="-ml-1 mr-3 h-6 w-6" aria-hidden="true" />
          Login with SMS
        </Button>

        <Button size="lg" variant="secondary" className="w-full" onClick={() => { setAuthState('email_start') }}>
          <MailIcon className="-ml-1 mr-3 h-6 w-6" aria-hidden="true" />
          Login with email
        </Button>
      </div>
    )
  }

  const renderEmailStart = () => {
    return (
      <>
        <div className="w-full">
          <p className="text-slate-600">
            Enter your email address below. We'll send you a confirmation code.
          </p>

          <TextField
            type="email"
            size="xl"
            autoFocus
            className="my-4"
            placeholder="Email"

            onChange={(e) => { setEmail(e.target.value); }}
            onKeyPress={onEnter(onEmailEntered)}
            value={email}
            disabled={emailRequestOut}
          />
          <div className="mt-2 w-full">
            {
              emailRequestError && (
                <Alert>
                  {emailRequestError?.response?.data?.message || 'There was an error.'}
                </Alert>
              )
            }
          </div>

          <div className="mt-2 w-full">
            <div className="grid grid-cols-2 gap-2">
              <Button variant="secondary" className="w-full" onClick={() => { setAuthState('start') }} disabled={emailRequestOut}>
                <ArrowSmLeftIcon className="-ml-1 mr-3 h-6 w-6" aria-hidden="true" />
                Back
              </Button>
              <Button variant="primary" className="w-full" onClick={onEmailEntered} loading={emailRequestOut}>
                Continue
                <ArrowSmRightIcon className="-mr-1 ml-3 h-6 w-6" aria-hidden="true" />
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderSMSStart = () => {
    return (
      <>
        <div className="w-full">
          <p className="text-slate-600">
            Enter your phone number below. We'll send you a confirmation code. Typical SMS rates apply.
          </p>
          <TextField
            type="tel"
            size="xl"
            autoFocus
            className="my-4"
            placeholder="Phone #"

            onChange={(e) => { setNumber(e.target.value); }}
            value={number}
            onKeyPress={onEnter(onPhoneEntered)}
          />
          <div className="mt-2 w-full">
            {
              smsRequestError && (
                <Alert className="mb-3">
                  {smsRequestError?.response?.data?.message || 'There was an error.'}
                </Alert>
              )
            }
          </div>

          <div className="mt-2 w-full">
            <div className="grid grid-cols-2 gap-2">
              <Button variant="secondary" className="w-full" onClick={() => { setAuthState('start') }} disabled={smsRequestOut}>
                <ArrowSmLeftIcon className="-ml-1 mr-3 h-6 w-6" aria-hidden="true" />
                Back
              </Button>
              <Button variant="primary" className="w-full" onClick={onPhoneEntered} loading={smsRequestOut}>
                Continue
                <ArrowSmRightIcon className="-mr-1 ml-3 h-6 w-6" aria-hidden="true" />
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderEmailVerify = () => {
    return (
      <>
        <div className="w-full">
          <p className="text-slate-600">
            We just sent a 6-digit code to {email}. Enter the code below:
          </p>

          <TextField
            type="tel"
            size="xl"
            autoFocus
            className="my-4"
            placeholder="6-digit code"

            onChange={(e) => { setEmailCode(e.target.value); }}
            onKeyPress={onEnter(verifyEmail)}
            value={emailCode}
          />

          <div className="mt-2 w-full">
            {
              emailVerifyRequestError && (
                <Alert className="mb-3">
                  {emailVerifyRequestError?.response?.data?.message || 'There was an error.'}
                </Alert>
              )
            }
          </div>

          <div className="mt-2 w-full">
            <div className="grid grid-cols-2 gap-2">
              <Button
                variant="secondary"
                className="w-full"
                disabled={emailRequestOut || emailVerifyRequestOut}
                onClick={onEmailEntered}
              >
                Resend code
              </Button>
              <Button
                variant="primary"
                className="w-full"
                loading={emailRequestOut || emailVerifyRequestOut}
                onClick={verifyEmail}
              >
                Continue
                <ArrowSmRightIcon className="-mr-1 ml-3 h-6 w-6" aria-hidden="true" />
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderSMSVerify = () => {
    return (
      <>
        <div className="w-full">
          <p className="text-slate-600">
            We just texted a 6-digit code to {number}. Enter the code below:
          </p>

          <TextField
            type="tel"
            size="xl"
            autoFocus
            className="my-4"
            placeholder="6-digit code"

            onChange={(e) => { setSmsCode(e.target.value); }}
            onKeyPress={onEnter(verifyPhone)}
            value={smsCode}
            disabled={smsVerifyRequestOut}
          />

          {/* <input
            placeholder="6-digit code"
            autoFocus
            value={smsCode}
            // disabled={sendRequestOut}
            onChange={(e) => { setSmsCode(e.target.value); }}
            onKeyPress={onEnter(verifyPhone)}
            className="placeholder-blue-500 outline-none disabled:opacity-60 form-input mb-1 focus:ring-2 focus:ring-slate-900 p-4 pl-3 w-full rounded-lg appearance-none bg-blue-700 text-white border-none focus:ring-2 text-xl"
          /> */}

          {
            smsVerifyRequestError && (
              <div className="mt-2 w-full">
                <Alert className="mb-3">
                  {smsVerifyRequestError?.response?.data?.message || 'There was an error.'}
                </Alert>
              </div>
            )
          }

          <div className="mt-2 w-full">
            <div className="grid grid-cols-2 gap-2">

              <Button
                variant="secondary"
                className="w-full"
                disabled={smsRequestOut || smsVerifyRequestOut}
                onClick={onPhoneEntered}
              >
                Resend code
              </Button>
              <Button
                variant="primary"
                className="w-full"
                loading={smsRequestOut || smsVerifyRequestOut}
                onClick={verifyPhone}
              >
                Continue
                <ArrowSmRightIcon className="-mr-1 ml-3 h-6 w-6" aria-hidden="true" />
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={classNames((smsRequestOut || smsVerifyRequestOut || emailRequestOut || emailVerifyRequestOut) && 'pointer-events-none')}>
      { authState === 'start' && renderStart() }
      { authState === 'sms_start' && renderSMSStart() }
      { authState === 'sms_verify' && renderSMSVerify() }
      { authState === 'email_start' && renderEmailStart() }
      { authState === 'email_verify' && renderEmailVerify() }
    </div>
  );
}
